import { marked } from 'marked'
import './App.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import React, { useState } from 'react';
import firstMarkDown from './demo';

import * as DOMPurify from '../node_modules/dompurify/dist/purify'



function App() {

  let [markdown, setMarkdown] = useState(firstMarkDown);

  marked.setOptions({
    breaks: true
  })

  function changeMarkdown(event) {
    if (event)
      setMarkdown(
        markdown = event.target.value
      )
    return { __html: DOMPurify.sanitize(marked.parse(markdown), { USE_PROFILES: { html: true } }) }
  }



  return (
    <div className="App container-sm" id='base'>
      <div className='row mx-auto' style={{'height': '90vh'}}>
      <div  className='col-4 border'>
        <div className='heading'>Markdown Editor</div>
        <textarea className='w-100 border rounded' value={markdown} onChange={changeMarkdown} id="editor" style={{ height: '90%' }}></textarea>
      </div>
      <div className='col-8 border'>
        <div className='heading'>Markdown Viewer</div>
        <div className='bg-dark bg-gradient text-light w-100 rounded border p-3' id='preview' dangerouslySetInnerHTML={changeMarkdown()}></div>
      </div>
      </div>
    </div>
     
  );
}

export default App;